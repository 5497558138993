import React, { Component } from 'react';
import Header from "./header";
import Footer from "./footer";


class Frame extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
        const { withHeader, withFooter, isHome } = this.props;
      return (
        <div>
          
        {withHeader && <Header isHome={isHome}></Header> } 
        
         {this.props.children}

        {withFooter &&  <Footer></Footer>}
        

        </div>
      );
    }
  }

  export default Frame;