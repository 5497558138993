import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <footer>
          <div className="container">
            <p>© Playpolygon 2022. All Rights Reserved</p>
          </div>
        </footer>
      );
    }
  }

  export default Footer;