import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter,Link} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Countdown from 'react-countdown';
import moment from 'moment';
import PlayPolygon from "../../contracts/PlayPolygon.json";
import getWeb3 from "../../getWeb3";
import Modal from 'react-bootstrap/Modal';
const level_array = [1,2,3,4,5];
const defaultAmountArr = ['25','100','500','1000','5000','10000','25000','50000'];

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        contractInfo: {
          contractAddress:'-',
          contractAddressLinkto: null,
          ReferredByContent:null,
          ReferredBy: null,
        },
        userInfo:{
          YourAddress: null,
          YourAddressfull:null,
          TotalWithdrawn:0,
          TotalDeposits:0,
          totalRevenue:0,
          level:0,
          start: 0
        },
        rewardInfo:{
          capitals:0,
          statics:0,
          directs:0,
          level4Freezed:0,
          level4Released:0,
          level5Freezed:0,
          level5Released:0,
          totalFreezed:0,
          star:0,
          luck:0,
          top:0,
          available_withdrawal:0
        },
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        amountDeposit:'',
        split_amount:'',
        startTime:null,
        totalUser:0,
        luckPool:0,
        starPool:0,
        topPool:0,
        register_modal_show:false,
        deposit_modal_show:false,
        withdraw_modal_show:false,
        split_modal_show:false,
        min_deposit:25,
        min_deposit_spit:50,
        lastDistribute:Math.floor(Date.now() / 1000),
        current_timestamp: Math.floor(Date.now() / 1000),
        reward_end_time: 1*24*3600,
        user_matic_balance:0,
        user_bsg_balance:0,
        receiver_address:'',
        getCurSplit:0,
        getTokenBalance:0,
        reward_percent:22.5,
        getOrderLength:0,
        orderInfos:[]

      }
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          //if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = PlayPolygon.networks;
          const instance = new web3.eth.Contract(
            PlayPolygon.abi,
            deployedNetwork && PlayPolygon.address,
          );
          let user_addres = accounts[0];
          // user_addres = '0x81CaF77eeA129867E3c07dc85Ee59A7B0B4036aa';
          //user_addres = '0x257ADf0419ba28e6665aB889dc99613ffF6eDf3B';
          
          this.setState({ web3, accounts:user_addres, contract: instance, contractAddress:PlayPolygon.address}, this.fetchData);
          }
       },1000);
  
      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        //console.error(error);
      }
    };
  
    fetchData= async()=>{
      const { accounts, contract } = this.state;

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      
      const contractAddress = this.state.contractAddress;
      const users = await contract.methods.userInfo(accounts).call();
      const referrer = users.referrer;
      let ReferredBy = null;
      let ReferredByContent = null;
      let emptyAddress = /^0x0+$/.test(users.referrer);
      if(users.referrer && !emptyAddress){
        ReferredBy = users.referrer;
        ReferredByContent = ReferredBy.slice(0, 10) + '.....' + ReferredBy.slice(ReferredBy.length-5, ReferredBy.length);
        this.setState({register_modal_show:false});
      }else{
        this.setState({register_modal_show:true});
      }

      this.setState(({contractInfo})=>
        ({contractInfo : {...contractInfo, 
          contractAddress:contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length),
          contractAddressLinkto:contractAddress,
          ReferredByContent: ReferredByContent,
          ReferredBy: (referrer != 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') ? ReferredBy : null
        }})
      );

      
      // if(!this.state.contractInfo.ReferredBy){
      //   this.setState({register_modal_show:true});
      // }else{
      //   this.setState({register_modal_show:false});
      // }
      
      let TotalDeposits = users.totalDeposit;
      let totalRevenue = users.totalRevenue;
      let level = users.level;
      let start = users.start;
      
      this.setState(({userInfo})=>
        ({userInfo : {...userInfo, 
          YourAddress: YourAddress,
          YourAddressfull:YourAddressfull,
          TotalDeposits:this.state.web3.utils.fromWei(TotalDeposits, 'ether'),
          totalRevenue:this.state.web3.utils.fromWei(totalRevenue, 'ether'),
          level:level,
          start:parseInt(start)
        }})
      );

      const rewardInfo = await contract.methods.rewardInfo(accounts).call();
      let capitals = rewardInfo.capitals;
      let statics = rewardInfo.statics;
      let directs = rewardInfo.directs;
      let level4Freezed = rewardInfo.level4Freezed;
      let level4Released = rewardInfo.level4Released;
      let level5Freezed = rewardInfo.level5Freezed;
      let level5Released = rewardInfo.level5Released;
      let star = rewardInfo.star;
      let luck = rewardInfo.luck;
      let top = rewardInfo.top;
      let totalFreezed = this.state.web3.utils.fromWei(level4Freezed, 'ether') + this.state.web3.utils.fromWei(level5Freezed, 'ether');
      
      let available_withdrawal = parseFloat(this.state.web3.utils.fromWei(capitals, 'ether')) + 
      parseFloat(this.state.web3.utils.fromWei(statics, 'ether')) + 
      parseFloat(this.state.web3.utils.fromWei(directs, 'ether')) +
      parseFloat(this.state.web3.utils.fromWei(level4Freezed, 'ether')) + 
      parseFloat(this.state.web3.utils.fromWei(level4Released, 'ether')) + 
      parseFloat(this.state.web3.utils.fromWei(level5Freezed, 'ether')) + 
      parseFloat(this.state.web3.utils.fromWei(level5Released, 'ether')) +
      parseFloat(this.state.web3.utils.fromWei(star, 'ether')) + 
      parseFloat(this.state.web3.utils.fromWei(luck, 'ether')) + 
      parseFloat(this.state.web3.utils.fromWei(top, 'ether'));


      this.setState(({rewardInfo})=>
        ({rewardInfo : {...rewardInfo,
          capitals:this.state.web3.utils.fromWei(capitals, 'ether'),
          statics:this.state.web3.utils.fromWei(statics, 'ether'),
          directs:this.state.web3.utils.fromWei(directs, 'ether'),
          level4Freezed:this.state.web3.utils.fromWei(level4Freezed, 'ether'),
          level4Released:this.state.web3.utils.fromWei(level4Released, 'ether'),
          level5Freezed:this.state.web3.utils.fromWei(level5Freezed, 'ether'),
          level5Released:this.state.web3.utils.fromWei(level5Released, 'ether'),
          totalFreezed:totalFreezed,
          star:this.state.web3.utils.fromWei(star, 'ether'),
          luck:this.state.web3.utils.fromWei(luck, 'ether'),
          top:this.state.web3.utils.fromWei(top, 'ether'),
          available_withdrawal:available_withdrawal
        }})
      );

      let min_deposit = this.state.min_deposit;
      let maxDeposit = this.state.web3.utils.fromWei(users.maxDeposit, 'ether');
      if(maxDeposit > 0){
        min_deposit = maxDeposit;
      }

      let startTime = await contract.methods.startTime().call();
      let lastDistribute = await contract.methods.lastDistribute().call();
      let totalUser = await contract.methods.totalUser().call();
      let luckPool = await contract.methods.luckPool().call();
      luckPool = this.state.web3.utils.fromWei(luckPool, 'ether');
      let starPool = await contract.methods.starPool().call();
      starPool = this.state.web3.utils.fromWei(starPool, 'ether');
      let topPool = await contract.methods.topPool().call();
      topPool = this.state.web3.utils.fromWei(topPool, 'ether');

      let user_matic_balance = await this.state.web3.eth.getBalance(accounts);
      user_matic_balance = this.state.web3.utils.fromWei(user_matic_balance, 'ether');

      let getCurSplit = await contract.methods.getCurSplit(accounts).call();
      getCurSplit = this.state.web3.utils.fromWei(getCurSplit, 'ether');

      // let getTokenBalance = await contract.methods.getTokenBalance(accounts).call();
      // getTokenBalance = this.state.web3.utils.fromWei(getTokenBalance, 'ether');


      this.setState({loading:false,startTime,lastDistribute,totalUser,luckPool,starPool,topPool,
        min_deposit,user_matic_balance,getCurSplit},async()=>{
          let orderInfos_data = [];
          let getOrderLength = await this.state.contract.methods.getOrderLength(this.state.accounts).call();
          
          if(getOrderLength>0){
            for(let i=0;i<getOrderLength;i++){
              let orderInfos = await this.state.contract.methods.orderInfos(this.state.accounts,i).call();
              
              let amount = orderInfos.amount;
              amount = this.state.web3.utils.fromWei(amount, 'ether');

              let start = orderInfos.start;

              let unfreeze = orderInfos.unfreeze;
              
              orderInfos_data.push({amount:amount,start:start,unfreeze:unfreeze,isUnfreezed:orderInfos.isUnfreezed});
            }
            this.setState({getOrderLength,orderInfos:orderInfos_data});
          }

        });

    }

    doRegister = async(e) => {
      if(!this.state.sponsor_address && !this.state.contractInfo.ReferredBy){
        this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        
        return false;
      }
      if(!this.state.web3.utils.isAddress(this.state.sponsor_address)){
        this.props.enqueueSnackbar("Sponsor Address is not valid!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }
      let ReferredAddress = '';
      if(this.state.contractInfo.ReferredBy){
        ReferredAddress = this.state.contractInfo.ReferredBy
      }
  
      if(this.state.sponsor_address){
        ReferredAddress = this.state.sponsor_address
      }

      try {
        this.setState({loading:true});
        const data = await this.state.contract.methods.register(ReferredAddress).send(
          {
            from: this.state.accounts,
            value:0
          }
        );
        if(data.status){
          this.handleClose();
          this.setState({loading:false,register_modal_show:false});
          this.props.enqueueSnackbar("Registered successfully!",{ variant: 'success' })
          this.fetchData();
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
          //////////// transaction error ////////////
        }
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }
        if(err.includes('Confirmation declined by user')){
          defaultError = err;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }
  
    doSubmit = async(e) => {  
      if(this.state.sponsor_address == this.state.userInfo.YourAddressfull){  
        this.props.enqueueSnackbar("Sponsor Address should not same Deposit Address!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }
  
      if(!this.state.sponsor_address && !this.state.contractInfo.ReferredBy){
          this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
          this.setState({sponsor_addressError:true});
          return false;
      }
      if(!this.state.amountDeposit){
          this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
          this.setState({amountError:true});
          return false;
      }
  
      if(parseInt(this.state.amountDeposit) < parseInt(this.state.min_deposit)){
        this.props.enqueueSnackbar(`Min deposit ${this.state.min_deposit} MATIC!`,{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }

      // if(this.state.amountDeposit%50 != 0){
      //   this.props.enqueueSnackbar(`Min deposit should be ratio of 50!`,{ variant: 'error' })
      //   this.setState({amountError:true});
      //   return false;
      // }

      let ReferredAddress = '';
  
      if(this.state.contractInfo.ReferredBy){
        ReferredAddress = this.state.contractInfo.ReferredBy
      }
  
      if(this.state.sponsor_address){
        ReferredAddress = this.state.sponsor_address
      }
  
      try {
        let balance = await this.state.web3.eth.getBalance(this.state.accounts);
        let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
        let amountBNB = this.state.amountDeposit;
        if(balanceEthVal >= amountBNB){
            this.setState({loading:true});
            let weiValue = this.state.web3.utils.toWei(this.state.amountDeposit, 'ether');
            const data= await this.state.contract.methods.deposit().send(
              {
                from: this.state.accounts,
                value:weiValue.toString()
              }
            );
            if(data.status){
              this.handleCloseDeposit();
              this.setState({loading:false});
              this.props.enqueueSnackbar("Congratulation! Your Deposit made successfully! ",{ variant: 'success' })
              this.fetchData();
            }else{
              this.handleCloseDeposit();
              this.setState({loading:false});
              this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
              //////////// transaction error ////////////
            }
  
        }else{
          this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' })                
        }
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.handleCloseDeposit();
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }
        if(err.includes('Confirmation declined by user')){
          defaultError = err;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }
  
    doWithdrawl = async(e) => {
      // if(!this.state.withdrawlAmount){
      //   this.props.enqueueSnackbar("Withdrawl Amount is required!",{ variant: 'error' });
      //   this.setState({withdrawlAmountError:true});
      //   return false;
      // }
  
      // if(this.state.withdrawlAmount < 50){
      //   this.props.enqueueSnackbar("Min Withdrawal 50 MATIC!",{ variant: 'error' })
      //   this.setState({withdrawlAmountError:true});
      //   return false;
      // }
  
      // if(this.state.userDetails.wallet_balance < 50){
      //   this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' });
      //   return false;
      // }

      // if(!this.state.captchaValueWithdrawal){
      //   this.props.enqueueSnackbar("Invalid Captcha!",{ variant: 'error' });
      //   return false;
      // }

      if(!this.state.userInfo.totalRevenue){
        this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' });
        this.setState({withdrawlAmountError:true});
        return false;
      }      
  
      try {
        this.setState({loading:true});
        const data = await this.state.contract.methods.withdraw().send(
          {
            from: this.state.accounts,
            value:0
          }
        );
        if(data.status){
          this.handleCloseWithdraw();          
          this.setState({withdrawlAmount:0, loading:false});
          this.props.enqueueSnackbar("Congratulation! Your Withdrawl made successfully!",{ variant: 'success' })
          this.fetchData();
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' });
          this.handleCloseWithdraw();
          this.setState({loading:false});
        }
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.includes('Confirmation declined by user')){
          defaultError = err;
        }
        this.handleCloseWithdraw();
        this.props.enqueueSnackbar(err,{ variant: 'error' })
      }
    }

    doSplit = async(e) => {
      if(this.state.userInfo.TotalDeposits != 0){
        this.props.enqueueSnackbar("You are already activated!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      } 
      if(!this.state.getCurSplit){
        this.props.enqueueSnackbar("Insufficient split Balance!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }

      if(!this.state.split_amount){
        this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }

      if(parseFloat(this.state.split_amount) < parseFloat(this.state.min_deposit_spit)){
        this.props.enqueueSnackbar(`Min deposit ${this.state.min_deposit_spit} MATIC!`,{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }

      if(this.state.split_amount%50 != 0){
        this.props.enqueueSnackbar(`Amount should be ratio of 50!`,{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }

      if(parseFloat(this.state.getCurSplit) < parseFloat(this.state.split_amount)){
        this.props.enqueueSnackbar("You don't have enough split Balance!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }
      
  
      try {      
        let balance = await this.state.web3.eth.getBalance(this.state.accounts);
        let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
        if(balanceEthVal >= this.state.split_amount){
            this.setState({loading:true});
            let weiValue = this.state.web3.utils.toWei(this.state.split_amount, 'ether');
            const data = await this.state.contract.methods.depositBySplit(weiValue.toString()).send(
              {
                from: this.state.accounts,
                value:0
              }
            );
            if(data.status){
              this.handleCloseSplit();
              this.setState({loading:false});
              this.props.enqueueSnackbar("Congratulation! Your Deposit made successfully! ",{ variant: 'success' })
              this.fetchData();
            }else{
              this.handleCloseSplit();
              this.setState({loading:false});
              this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
              //////////// transaction error ////////////
            }
  
        }else{
          this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' })                
        }    
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.handleCloseSplit();
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }
        if(err.includes('Confirmation declined by user')){
          defaultError = err;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }

    doTransfer = async(e) => {
      if(!this.state.split_amount){
        this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }
      if(parseFloat(this.state.split_amount) < parseFloat(this.state.min_deposit_spit)){
        this.props.enqueueSnackbar(`Min deposit ${this.state.min_deposit_spit} MATIC!`,{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }

      if(this.state.split_amount%50 != 0){
        this.props.enqueueSnackbar(`Amount should be ratio of 50!`,{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }

      if(parseFloat(this.state.getCurSplit) < parseFloat(this.state.split_amount)){
        this.props.enqueueSnackbar("You don't have enough split Balance!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }

      if(this.state.receiver_address == this.state.userInfo.YourAddressfull){     
  
        this.props.enqueueSnackbar("Receiver Address should not same Deposit Address!",{ variant: 'error' })
  
        this.setState({sponsor_addressError:true});
        return false;
      }
  
      if(!this.state.receiver_address){
          this.props.enqueueSnackbar("Receiver Address is required!",{ variant: 'error' })
          this.setState({sponsor_addressError:true});
          return false;
      }

      if(!this.state.web3.utils.isAddress(this.state.receiver_address)){
          this.props.enqueueSnackbar("Receiver Address is not valid!",{ variant: 'error' })
          this.setState({sponsor_addressError:true});
          return false;
      }
  
      try {           
        let balance = await this.state.web3.eth.getBalance(this.state.accounts);
        let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
        if(balanceEthVal >= this.state.split_amount){
            this.setState({loading:true});
            let weiValue = this.state.web3.utils.toWei(this.state.split_amount, 'ether');
            const data = await this.state.contract.methods.transferBySplit(this.state.receiver_address,weiValue.toString()).send(
              {
                from: this.state.accounts,
                value:0
              }
            );
            if(data.status){
              this.handleCloseSplit();
              this.setState({loading:false});
              this.props.enqueueSnackbar("Congratulation! Your Transfer made successfully! ",{ variant: 'success' })
              this.fetchData();
            }else{
              this.handleCloseSplit();
              this.setState({loading:false});
              this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
              //////////// transaction error ////////////
            }
  
        }else{
          this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' })                
        }    
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.handleCloseSplit();
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }
        if(err.includes('Confirmation declined by user')){
          defaultError = err;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }

    handleClose = () => {
      this.setState({register_modal_show:false});
    }

    handleCloseDeposit = () => {
      this.setState({deposit_modal_show:false});
    }

    handleCloseWithdraw = () => {
      this.setState({withdraw_modal_show:false});
    }

    handleCloseSplit = () => {
      this.setState({split_modal_show:false});
    }

    render() {
      let this2 = this;
      let percet_amount = parseFloat(this.state.amountDeposit?this.state.amountDeposit:0);
      percet_amount = percet_amount + (percet_amount*this.state.reward_percent)/100;

      return (
        <Frame withHeader={true} withFooter={true} isHome={true}>
          {this.state.loading ? (
            <div className="loader-container">
              <div className="loader-inner ball-scale-multiple">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (null)}

          <Modal
            show={this.state.register_modal_show}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
           
            <Modal.Body>
              {/* <h4>Register</h4> */}
              <div className='form-group'>
                <label className='mb-3'>Sponsor Address</label>
                {!this.state.contractInfo.ReferredBy ? (
                  <input className="form-control" type="text" placeholder="Sponsor Address"
                    name="sponsor_address"
                    onChange={e => this.setState({sponsor_address:e.target.value})}
                    value={this.state.sponsor_address || ''}
                    error={this.state.sponsor_addressError}
                  />
                ):(
                  <input className="form-control" type="text" placeholder="Sponsor Address"
                    readOnly
                    value={this.state.contractInfo.ReferredBy || ''}
                  />
                )}
                <div className="deposit_box mt-4">
                  <button className="deposit btn-block" disabled={this.state.loading} onClick={e => this.doRegister(e)}>Register</button>
                </div>
              </div>
              
            </Modal.Body>
            
          </Modal>

          <Modal
            show={this.state.deposit_modal_show}
            onHide={this.handleCloseDeposit}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title>Deposit                
              </Modal.Title>
              <i onClick={this.handleCloseDeposit} className='fa fa-close' style={{cursor:'pointer',fontSize:20}}></i>
            </Modal.Header>
            <Modal.Body>
              {/* <div className='form-group'>
                <label>Amount</label>
                <input className="form-control" type="number" placeholder={`Min Amount ${this.state.min_deposit} MATIC`}
                  name="amountDeposit"
                  onChange={e => this.setState({amountDeposit:e.target.value})}
                  value={this.state.amountDeposit || ''}
                  error={this.state.amountDepositError}
                />
                <em>Minimum deposit {this.state.min_deposit} MATIC. A ratio of 50 max 2000</em>
              </div> */}

              <div className="row">
                {defaultAmountArr.map((amount,key) => {return(
                  <div className="col-md-4 col-sm-4 col-lg-3 col-6" key={key}>
                      <button className={this.state.amountDeposit==amount?`package_box bg-gradient-${key} active`:`package_box bg-gradient-${key} inactive`}
                        onClick={e => this.setState({amountDeposit:amount})}>
                        {amount+' MATIC'}
                      </button>
                  </div>
                  )                              
                })}
              </div>

              <div className="recDS Huans mt-4">
                <div className="recDfc">
                  <div className="Tc-czDfcB flexC fl-bet">
                    <h3><span className="depositAmount">{this.state.amountDeposit || 0}</span><span> MATIC</span></h3>
                    <p>+</p>
                    <h3>{this.state.reward_percent}<span>%</span></h3>
                    <p>=</p>
                    <h3><span className="total">{parseFloat(percet_amount).toFixed(2)}</span><span> MATIC</span></h3>
                  </div>
                  <div className="recDfcP flexC fl-bet">
                    <p>
                      Deposit
                    </p>
                    <p>
                      Each cycle
                    </p>
                    <p>
                      Deposit and interest
                    </p>
                  </div>
                </div>
                <div className="recDSp">
                  <p>
                    15 days per cycle. {this.state.reward_percent}% per cycle 
                  </p>
                  <p>
                    You will have to redeposit every time after each cycle. It will have to be either the same amount or bigger amount. Every 2 cycle you deposit 1 extra days will be added without extra rewards. Maximum 45 days.
                  </p>
                </div>
              </div>
              
            </Modal.Body>
            <Modal.Footer className='deposit_box'>
              <div className="">
                <div className="">
                  <button className="deposit" disabled={this.state.loading} onClick={e => this.doSubmit(e)}>Confirm</button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.withdraw_modal_show}
            onHide={this.handleCloseWithdraw}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title>Withdraw
              </Modal.Title>
              <i onClick={this.handleCloseWithdraw} className='fa fa-close' style={{cursor:'pointer',fontSize:20}}></i>
            </Modal.Header>
            <Modal.Body>
              <div className="card-body" style={{padding:0}}>
                <div className="income_text modal_p">                  
                  <p>
                    Unlock principal
                    <span>{parseFloat(this.state.rewardInfo.capitals).toFixed(2)} MATIC</span>
                  </p>
                  <p>
                    Cycle reward
                    <span>{parseFloat(this.state.rewardInfo.statics).toFixed(2)} MATIC</span>
                  </p>
                  <p>
                    1st level
                    <span>{parseFloat(this.state.rewardInfo.directs).toFixed(2)} MATIC</span>
                  </p>
                  <p>
                    2-5 level
                    <span>{parseFloat(this.state.rewardInfo.level4Released).toFixed(2)} MATIC</span>
                  </p>
                  <p>
                    6-20 level
                    <span>{parseFloat(this.state.rewardInfo.level5Released).toFixed(2)} MATIC</span>
                  </p>
                  <p>
                    Freezing
                    <span>{parseFloat(this.state.rewardInfo.totalFreezed).toFixed(2)} MATIC</span>
                  </p>
                  <p>
                    Lucky reward
                    <span>{parseFloat(this.state.rewardInfo.luck).toFixed(2)} MATIC</span>
                  </p>
                  <p>
                    4 star reward
                    <span>{parseFloat(this.state.rewardInfo.star).toFixed(2)} MATIC</span>
                  </p>
                  <p>
                    Top player reward
                    <span>{parseFloat(this.state.rewardInfo.top).toFixed(2)} MATIC</span>
                  </p>
                  <hr className='hr_border' />
                  <p className='bt-0'>
                    <strong>Available withdrawal</strong>
                    <span><strong>{parseFloat(this.state.rewardInfo.available_withdrawal).toFixed(2)} MATIC</strong></span>
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className='deposit_box'>
              <div className="">                
                <div className="">
                  <button className="withdraw" disabled={this.state.loading} onClick={e => this.doWithdrawl(e)}>Withdraw</button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.split_modal_show}
            onHide={this.handleCloseSplit}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title>Split Account                
              </Modal.Title>
              <i onClick={this.handleCloseSplit} className='fa fa-close' style={{cursor:'pointer',fontSize:20}}></i>
            </Modal.Header>
            <Modal.Body>
              <div className='form-group'>
                <label>MATIC</label>
                <input className="form-control" type="text" placeholder={`MATIC`}
                  name="user_bsg_balance"
                  value={this.state.getCurSplit || ''}
                  disabled
                />
              </div>
              <div className='form-group'>
                <label>Amount</label>
                <input className="form-control" type="number" placeholder={`Min Amount ${this.state.min_deposit_spit} MATIC`}
                  name="split_amount"
                  onChange={e => this.setState({split_amount:e.target.value})}
                  value={this.state.split_amount || ''}
                  error={this.state.split_amountError}
                />
                <em>The ratio of {this.state.min_deposit_spit}</em>
              </div>
              <div className='form-group'>
                <label>Receiver address</label>
                <input className="form-control" type="text" placeholder={`Receiver address`}
                  name="receiver_address"
                  onChange={e => this.setState({receiver_address:e.target.value})}
                  value={this.state.receiver_address || ''}
                  error={this.state.receiver_addressError}
                />
                
              </div>
              
            </Modal.Body>
            <Modal.Footer className='deposit_box'>
              <div className="row">
                <div className="col-6">
                  <button className="split_account"disabled={this.state.loading} onClick={e => this.doSplit(e)}>Deposit</button>
                </div>
                <div className="col-6">
                  <button className="energy_lend"disabled={this.state.loading} onClick={e => this.doTransfer(e)}>Transfer</button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-3 col-12">
                  <div className="contract_box bg1">
                    <h3>Contract address :</h3>
                    <p>
                      <a href={`https://polygonscan.com/address/${this.state.contractInfo.contractAddressLinkto}/code`}>{this.state.contractInfo.contractAddress}</a>
                      {/* <a href={`https://mumbai.polygonscan.com/address/${this.state.contractInfo.contractAddressLinkto}/code`}>{this.state.contractInfo.contractAddress}</a> */}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-3 col-12">
                  <div className="contract_box bg2">
                    <h3>Platform Running time : </h3>
                      <p>
                        {this.state.startTime ? (
                          moment(this.state.startTime*1000).fromNow()
                        ):('-')}
                      
                      </p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-3 col-12">
                  <div className="contract_box bg3">
                    <h3>Income :</h3> 
                    <p>15 days per cycle. Monthly 45%</p>   
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-3 col-12">
                  <div className="contract_box bg4">
                    <h3>Deposit time :</h3>
                    <p className="d-item_two">
                      {this.state.userInfo.start?(
                        moment(this.state.userInfo.start*1000).format('LLL')
                      ):(
                        '---'
                      )}
                    </p>
                  </div>
                </div>
              
                <div className="col-md-6 col-sm-6 col-lg-3 col-12">
                  <div className="contract_box bg1">
                    <h3>Players</h3>
                    <p>{this.state.totalUser}</p>
                  </div>       
                </div>
                <div className="col-md-6 col-sm-6 col-lg-3 col-12">
                  <div className="contract_box bg2">
                    <h3>Daily Lucky pool</h3>
                    <p>{parseFloat(this.state.luckPool).toFixed(2)} MATIC</p>
                  </div>       
                </div>
                <div className="col-md-6 col-sm-6 col-lg-3 col-12">
                  <div className="contract_box bg3">
                    <h3>Daily 4 Star Pool</h3>
                    <p>{parseFloat(this.state.starPool).toFixed(2)} MATIC</p>
                  </div>       
                </div>
                <div className="col-md-6 col-sm-6 col-lg-3 col-12">
                  <div className="contract_box bg4">
                      <h3>Daily Top 3 Pool</h3>
                      <p>{parseFloat(this.state.topPool).toFixed(2)} MATIC</p>
                  </div>       
                </div>
              </div>
            </div>
          </section>

         

          <section className="pt-4">
            <div className="container">
              <div className="card-container">
                <div className="">
                  <div className="">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-4">
                        <div className="deposit_box">
                          <img src="img/deposit.png" alt="" />
                          <button className="deposit" disabled={this.state.loading} onClick={(e)=>this.setState({deposit_modal_show:true})}>Deposit</button>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4">
                        <div className="deposit_box">
                          <img src="img/Withdraw.png" alt="" />
                          <button className="withdraw" disabled={this.state.loading} onClick={(e)=>this.setState({withdraw_modal_show:true})}>Withdraw</button>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4">
                        <div className="deposit_box">
                          <img src="img/split_account.png" alt="" />
                          <button disabled={this.state.loading} onClick={(e)=>this.setState({split_modal_show:true})} className="split_account">Split account</button>
                        </div>
                      </div>
                      {/* <div className="col-md-6 col-sm-6 col-lg-6">
                        <div className="deposit_box">
                          <img src="img/energy_lend.png" alt="" />
                          <a target={'_blank'} href="https://www.tokengoodies.com/trade" className="energy_lend">Energy lend</a>
                        </div>
                      </div> */}
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-4">
            <div className="container">
              <div className="card-container">
                <div className="">
                  <div className="">
                    <div className="income_text">
                      <p>My level
                      <span>
                        {level_array.map((val,key) => {
                          let _class = 'inactive';
                          if(this.state.userInfo.level>=val){
                            _class = 'active'
                          }
                          return(                          
                            <i key={`level${key}`} className={`fa fa-star-o ${_class}`}></i>                          
                          )
                        })}

                        <i data-toggle="tooltip" rel='tooltip' data-placement="top" className="fa fa-question-circle pointer" title='one-star player, two-star player, three-star player, four-star player, five-star player'></i>
                      </span>
                    </p>
                      <p>
                        Income<span>{parseFloat(this.state.userInfo.totalRevenue).toFixed(2)} MATIC</span>
                      </p>
                      <p>
                        Matic Balance<span>{parseFloat(this.state.user_matic_balance).toFixed(2)}</span>
                      </p>
                      <p>
                        Token Balance<span>{parseFloat(this.state.getTokenBalance).toFixed(2)}</span>
                      </p>
                      <p>
                        Referral<span>{this.state.contractInfo.ReferredByContent}</span>
                      </p>
                      <p className="deposit_box">
                        My address<span>
                        <CopyToClipboard text={`https://playpolygon.com/${this.state.userInfo.YourAddressfull}`}
                          onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                          <button className="split_account">Copy</button>
                        </CopyToClipboard>
                          
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-4">
            <div className="container">
              <div className="card-container">
                <h5 className='mb-2'>Deposit details</h5>
                <div className='table-responsive'>
                  <table className="table table-info">
                    <thead>
                      <tr>
                        <th >Amount</th>
                        <th >Deposit Date</th>
                        <th >Unlock Time</th>
                        <th >Reward</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orderInfos.length ? (
                        this.state.orderInfos.length>0 ? (
                          this.state.orderInfos.map(function(val, index){
                            let class_name = 'lebel_'+val.level;
                            if(val.level == 0){
                              class_name = 'current_user';
                            }
                            return (
                              <tr key={`cl${index}`} className={class_name}>
                                <td >{val.amount}</td>
                                <td >{moment(val.start*1000).format('LLL')}</td>
                                <td >{moment(val.unfreeze*1000).format('LLL')}</td>
                                <td >{parseFloat(val.amount)+(val.amount*this2.state.reward_percent)/100}</td>
                              </tr>
                            )
                          })
                        ):(null)
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">No Data Available!</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>

          <section className="pb-4 pt-2">
            <div className="container">
              <div className="card-container">
                <div className="time_box bg4">
                  <div className="info">
                    <a data-toggle="tooltip" rel='tooltip' data-placement="top" title="Daily last 10 depositor shall be rewarded">Info <i className="fa fa-question-circle"></i></a>
                  </div>
                  <div className="d-inline-flex">
                    <div>
                      <span className="sm_box"><i className="fa fa-clock-o"></i></span>
                    </div>
                    <div>
                      <h5>Lucky Reward</h5>
                      <h6>Time Remaning</h6>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="time_count" id="timer">
                      <Countdown intervalDelay={0} key='lucky'
                        precision={3} onComplete={()=>this.setState({is_flush_next:true, is_flush_one:true})} date={this.state.lastDistribute*1000+this.state.reward_end_time*1000}
                        daysInHours={true}
                        renderer={props => (
                          <>
                           {props.hours}<span>H</span> {props.minutes}<span>M</span> {props.seconds}<span>S</span>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="time_box bg2">
                  <div className="info">
                    <a data-toggle="tooltip" rel='tooltip' data-placement="top" title="Top 3 inviter daily shall split the pool daily.">Info <i className="fa fa-question-circle"></i></a>
                  </div>
                  <div className="d-inline-flex">
                    <div>
                      <span className="sm_box"><i className="fa fa-trophy"></i></span>
                    </div>
                    <div>
                      <h5>Daily top Reward</h5>
                      <h6>Time Remaning</h6>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="time_count" id="timer2">
                      <Countdown intervalDelay={0} key='daily'
                        precision={3} onComplete={()=>this.setState({is_flush_next:true, is_flush_one:true})} date={this.state.lastDistribute*1000+this.state.reward_end_time*1000}
                        daysInHours={true}
                        renderer={props => (
                          <>
                           {props.hours}<span>H</span> {props.minutes}<span>M</span> {props.seconds}<span>S</span>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="time_box bg3">
                  <div className="info">
                    <a data-toggle="tooltip" rel='tooltip' data-placement="top" title="4 star player shall split the daily pool daily.">Info <i className="fa fa-question-circle"></i></a>
                  </div>
                  <div className="d-inline-flex">
                    <div>
                      <span className="sm_box"><i className="fa fa-star-o"></i></span>
                    </div>
                    <div>
                      <h5>4 Star Reward</h5>
                      <h6>Time Remaning</h6>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="time_count" id="timer3">
                      <Countdown intervalDelay={0} key='star'
                        precision={3} onComplete={()=>this.setState({is_flush_next:true, is_flush_one:true})} date={this.state.lastDistribute*1000+this.state.reward_end_time*1000}
                        daysInHours={true}
                        renderer={props => (
                          <>
                           {props.hours}<span>H</span> {props.minutes}<span>M</span> {props.seconds}<span>S</span>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    },
  }
  
  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;